import * as React from 'react';
import { useEffect, useState } from 'react';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import config from 'src/constants';
import { Tracking } from '../Tracking/Tracking';

const messages = defineMessages({
    cookieConsent: {
        id: 'cookieConsent',
        defaultMessage: 'Az oldal böngészésével hozzájárulsz a sütik használatához. További info az <a>Adatkezelési tájékoztatóban</a>.'
    },
    cookieConsentAccept: {
        id: 'cookieConsentAccept',
        defaultMessage: 'Elfogadom'
    },
    cookieConsentDecline: {
        id: 'cookieConsentDecline',
        defaultMessage: 'Elutasítom'
    }
});

export interface ICookieConsentProps {
    barionPixelIds: string[];
}

const CookieConsentFooter: React.FunctionComponent<ICookieConsentProps> = ({ barionPixelIds }) => {
    const intl = useIntl();
    const [hasConsent, setHasConsent] = useState(false);
    const acceptText = intl.formatMessage(messages.cookieConsentAccept);
    const declineText = intl.formatMessage(messages.cookieConsentDecline);

    useEffect(() => {
        const cookieConsentValue = getCookieConsentValue();

        if (cookieConsentValue === 'true') {
            handleAcceptCookie();
        }
    }, []);

    const handleAcceptCookie = () => {
        setHasConsent(true);
    };

    const handleDeclineCookie = () => {
        setHasConsent(false);

        Cookies.remove('_ga');
        Cookies.remove('_gat');
        Cookies.remove('_gid');
    };

    return (
        <>
            <CookieConsent
                disableStyles={true}
                enableDeclineButton={true}
                containerClasses='cookie-consent'
                contentClasses='cookie-consent__content'
                buttonClasses='button button--primary cookie-consent__button'
                declineButtonClasses='button button--secondary cookie-consent__button cookie-consent__button--decline'
                buttonWrapperClasses='cookie-consent__button-wrapper'
                buttonText={acceptText}
                declineButtonText={declineText}
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}>
                <FormattedMessage {...messages.cookieConsent} values={{
                    a: (msg: string) =>
                        <a className='link' target='_blank' rel='noopener noreferrer'
                            href={getCookieConsentLink(intl.locale)}>
                            {msg}
                        </a>
                }} />
            </CookieConsent>
            <Tracking
                barionPixelIds={barionPixelIds}
                hasConsent={hasConsent} />
        </>

    )
}

const getCookieConsentLink = (language: string): string => {
    return `${config.COOKIE_CONSENT_LINK}${language}.html`;
}

export default CookieConsentFooter;
