import { IDTO } from 'src/entities/common';
import { IContactInfo, ICompany, FrontendType, PaymentType } from '../../entities/company';
import baseResponse from './baseResponse';

const timeTables = Array.from(Array(2)).map((val: any, i: number) => {
    return {
        Name: `’${String.fromCharCode(65 + i)}’ hét`,
        Days:
            Array.from(Array(7)).map((val2: any, i2: number) => {
                return {
                    DayNum: i2,
                    Start: '08:00',
                    End: '17:00',
                    DayOff: i2 > 4
                }
            })
    }
})

export const companyResponse: IDTO<ICompany> = {
    ...baseResponse,
    Data: {
        Name: 'Test Company',
        ShortDescription: 'This is a short test company description.',
        Description: '<p style="font-weight: 800"> This is a bold test sentence.</p> This is a test company description. This is a test company description. This is a test company description. This is a test company description.',
        Timetables: timeTables,
        LogoLink: 'https://placeimg.com/150/150/animals',
        BackgroundLink: 'https://placeimg.com/1000/500/nature',
        DefaultUserCulture: 1,
        IsFormalCulture: false,
        CanUserModify: true,
        RequiredFieldInfo: {
            IsEmailRequired: true,
            IsRegEmailRequired: false,
            IsPhoneRequired: true,
            IsRegPhoneRequired: false,
            IsNameRequired: true,
            IsRegNameRequired: false
        },
        ReservationOrder: 1,
        CanUserSelectCalendar: true,
        TimeZone: 'Europe/Budapest',
        IsEmailSendingEnabled: true,
        ShouldDisplayFirstTime: true,
        FrontendType: FrontendType.React,
        IsCartDisabled: false,
        PaymentSettings: {
            IsPaymentEnabled: true,
            IsBillingEnabled: true,
            DefaultCurrency: 'HUF',
            PaymentType: PaymentType.NeedPay
        }
    }
};

export const contactInfoResponse: IDTO<IContactInfo> = {
    ...baseResponse,
    Data: {
        PublicEmail: 'john@example.org',
        PublicPhoneNumber: '+36 30 123 4567',
        Address: 'Example St 45\nBudapest\n1001',
        MapLink: 'google.com',
        OpeningHours: 'Sometimes open, sometimes closed.',
        WebPageLink: 'https://google.com',
        FacebookPageLink: 'https://facebook.com'
    }
}

export default companyResponse;
