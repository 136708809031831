
export interface IDay {
    DayNum: number;
    Start: string;
    End: string;
    DayOff: boolean
}

export interface ITimetable {
    Name: string;
    Days: IDay[];
}

export interface ICompany {
    Name: string;
    ShortDescription: string;
    Description: string;
    Timetables: ITimetable[];
    LogoLink: string;
    BackgroundLink: string;
    DefaultUserCulture: number;
    IsFormalCulture: boolean;
    CanUserModify: boolean;
    RequiredFieldInfo: IRequiredFieldInfo;
    ReservationOrder: ReservationOrder;
    CanUserSelectCalendar: boolean;
    TimeZone: string;
    IsEmailSendingEnabled: boolean;
    ShouldDisplayFirstTime: boolean;
    DefaultCountryCode?: string;
    FrontendType: FrontendType;
    CustomSuccessLink?: string;
    IsCartDisabled: boolean;
    PaymentSettings: IPaymentSettings;
    TermsAndConditionsLink?: string;
}

export interface IContactInfo {
    PublicEmail: string | null;
    PublicPhoneNumber: string | null;
    OpeningHours: string | null;
    Address: string | null;
    MapLink: string | null;
    WebPageLink: string | null;
    FacebookPageLink: string | null;
}

export const defaultContactInfo: IContactInfo = {
    PublicEmail: null,
    PublicPhoneNumber: null,
    OpeningHours: null,
    Address: null,
    MapLink: null,
    WebPageLink: null,
    FacebookPageLink: null
}

export interface IDataPolicy {
    AdminPrivacyStatement: string;
    ClientApprovalStatement: string;
}

export const CompanyCultures: string[] = ['hu', 'en', 'sk', 'de'];

export interface IRequiredFieldInfo {
    IsEmailRequired: boolean;
    IsRegEmailRequired: boolean;
    IsPhoneRequired: boolean;
    IsRegPhoneRequired: boolean;
    IsNameRequired: boolean;
    IsRegNameRequired: boolean;
}

export const defaultRequiredInfo: IRequiredFieldInfo = {
    IsEmailRequired: true,
    IsRegEmailRequired: true,
    IsPhoneRequired: true,
    IsRegPhoneRequired: true,
    IsNameRequired: true,
    IsRegNameRequired: true
}

export enum ReservationOrder {
    CalendarFirst = 1,
    ServiceFirst = 2,
    ServiceFirstWithAllCalendarOption = 4,
    ServiceWithoutCalendar = 8,
    OnlyPreReservation = 16
}

export enum FrontendType {
    Classic = 0,
    React = 1,
    Inactive = 2
}

export const hasContactInfo = (contactInfo: IContactInfo | null) =>
    !!(contactInfo && (
        contactInfo.PublicEmail
        || contactInfo.PublicPhoneNumber
        || contactInfo.Address
        || contactInfo.OpeningHours
        || contactInfo.WebPageLink
        || contactInfo.FacebookPageLink)
    )

export const hasAboutUs = (company: ICompany | null) => {
    return !!(company && company.Description)
}

export interface ITrackingCodes {
    FacebookPixel: string | null;
    GATrackingId: string | null;
    GASourceDomain: string | null;
    GTMTrackingId: string | null;
    FacebookDomainVerificationId: string | undefined;
}

export interface ICustomCssAndJs {
    StyleSheet: string;
    Script: string;
}

export enum PaymentType {
    NoNeedToPay = 1,
    NeedAccept = 2,
    NeedPay = 3
}

export interface IPaymentSettings {
    IsPaymentEnabled: boolean;
    IsBillingEnabled: boolean;
    DefaultCurrency: string;
    PaymentType: PaymentType;
}
