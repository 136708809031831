import React from 'react';
import { Bone } from 'src/components/Placeholder/Placeholder';

export interface ISelectValueType {
    value: string | number | undefined,
    label: string | undefined
};

interface ISelectBaseProps {
    className?: string;
}

interface ISelectProps extends ISelectBaseProps {
    onChange?: (value?: string) => void;
    values: ISelectValueType[];
    value?: string | number | undefined;
    icon?: React.ReactElement;
    id?: string;
}

const Select: React.FunctionComponent<ISelectProps> = (props) => {
    const { values, value: selectedValue, className, onChange, id, icon } = props;

    const handleOnchange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(event.target.value);
    }

    return (
        <div className={'select ' + (className || '')} >
            {icon}
            <select id={id} onChange={handleOnchange} value={selectedValue}>
                {values.map(({ value, label }) =>
                    <option
                        key={'' + value + label}
                        value={value}
                    >
                        {label}
                    </option>
                )}
            </select>
        </div>
    );

}

export const SelectSkeleton: React.FC<ISelectBaseProps> = ({ className }) =>
    <Bone className={'select ' + (className || '')} />

export default Select;



