import { AppLocale } from './entities/modules';
import isDevEnv from './services/isDevEnv';

export const DEFAULT_REQUIRED_MODULE_PROPS: string[] = [];

export const DEV_ENV: string = 'development';
export const PROD_ENV: string = 'production';

export const DEFAULT_LOCALE: AppLocale = AppLocale.Hu;

interface IConfigConstantsBase {
    MAIN_PAGE_URL: string;
    MIN_PASSWORD_LENGTH: number;
    MAX_PASSWORD_LENGTH: number;
    FULL_CALENDAR_LICENCE_KEY?: string;
    COOKIE_CONSENT_LINK: string;
    SERVER_TIME_ZONE: string;
}

interface IConfigConstants extends IConfigConstantsBase {
    BASE_API_URL: string;
    API_CLIENT_ID: string;
    SMARTLOOK_CODE?: string;
    GA_TRACKING_ID?: string;
    MVC_URL: string;
};

const defaultConfig: IConfigConstantsBase = {
    MAIN_PAGE_URL: 'bookedby.me',
    MIN_PASSWORD_LENGTH: 6,
    MAX_PASSWORD_LENGTH: 100,
    FULL_CALENDAR_LICENCE_KEY: '0130032371-fcs-1566461845',
    COOKIE_CONSENT_LINK: 'https://bookedby.me/content/bookedby.me/privacyhtml/Privacy-',
    SERVER_TIME_ZONE: 'Europe/Berlin'
}

const localDevConfig: IConfigConstants = {
    ...defaultConfig,
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:3001',
    API_CLIENT_ID: '91ffb7da-b4f1-43cb-961a-1d24456dcf80',
    MVC_URL: process.env.REACT_APP_MVC_URL || ''
};

const prodBaseConfig: IConfigConstants = {
    ...defaultConfig,
    BASE_API_URL: '/rest-v2/api',
    API_CLIENT_ID: '91ffb7da-b4f1-43cb-961a-1d24456dcf80',
    MVC_URL: ''
};

const AWSDEVConfig: IConfigConstants = {
    ...prodBaseConfig,
    SMARTLOOK_CODE: 'aedc5503e49f97ead71d5aa7c56486137569fbc6',
    GA_TRACKING_ID: 'G-RG7GSMP33G'
}

const prodAWSHUConfig: IConfigConstants = {
    ...prodBaseConfig,
    MAIN_PAGE_URL: 'booked4.us',
    SMARTLOOK_CODE: 'f0372023fc50a6ca6f61f46a5b201c5b78767adb',
    GA_TRACKING_ID: 'G-5LMV5PPG1S',
    COOKIE_CONSENT_LINK: 'https://booked4.us/content/booked4.us/privacyhtml/Privacy-'
}

const prodAWSDEConfig: IConfigConstants = {
    ...prodBaseConfig,
    MAIN_PAGE_URL: 'termin-direkt.de',
    SMARTLOOK_CODE: '5cea1d280e3ec91ac80d9862df12ec12f955b83f',
    GA_TRACKING_ID: 'G-1BY05JQKKG',
    COOKIE_CONSENT_LINK: 'https://termin-direkt.de/content/termin-direkt.de/privacyhtml/Privacy-'
}

const getProdConfig = (): IConfigConstants => {
    switch (process.env.REACT_APP_CONFIG_ENV) {
        case 'devAWS':
            return AWSDEVConfig;
        case 'prodAWSHU':
            return prodAWSHUConfig;
        case 'prodAWSDE':
            return prodAWSDEConfig;
        default:
            return AWSDEVConfig;
    }
}

const config = isDevEnv() ? localDevConfig : getProdConfig();

export default config;